import { createI18n } from 'vue-i18n'

// Function to detect user's country using GeoIP
async function detectUserCountry() {
    try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();

        // Set Spanish for Latin American countries and Spain
        const spanishSpeakingCountries = [
            "ES", // Spain
            "AR",
            "BO",
            "CL",
            "CO",
            "CR",
            "CU",
            "DO",
            "EC",
            "SV",
            "GT",
            "HN",
            "MX",
            "NI",
            "PA",
            "PY",
            "PE",
            "PR",
            "UY",
            "VE",
        ];

        return spanishSpeakingCountries.includes(data.country_code) ? "es" : "en";
    } catch (error) {
        console.error("Error detecting country:", error);
        return "en"; // Default to English on error
    }
}

const i18n = createI18n({
    locale: await detectUserCountry(),
    fallbackLocale: 'es',
    messages: {
        en: {
            login: {
                login_now: 'Login now!',
                login_now_description: "Login now to access the most powerful AI tools!",
                forgot_password: 'Forgot password?',
                register_new_account: 'Register a new account',
                username: 'Username',
                password: 'Password',
                login: 'Login',
            },
            register: {
                register_now: 'Register now!',
                register_now_description: 'Make the first step to get access to powerful AI tools.',
                username: 'Username',
                email: 'Email',
                password: 'Password',
                confirm_password: 'Confirm Password',
                register: 'Register',
                company_step: {
                    title: 'Name your Company',
                    description: 'All the client information, documents templates and generations will be kept inside your company.',
                    company_name: 'Company Name',
                    assistant_name: 'Assistant Name',
                    assistant_context: 'Assistant Context',
                    create_company: 'Create Company'
                },
                final_step: {
                    start_now: 'Start Now'
                },
                steps: {
                    user: 'User',
                    company: 'Company',
                    register: 'Register'
                }
            },
            sidenav: {
                select_company: 'Select a Company',
                dashboard: 'Dashboard',
                ai_parameters: 'AI Parameters',
                ai_tokens: 'AI Tokens',
                files: 'Files',
                sandbox: 'Sandbox',
                users: 'Users',
                subscriptions: 'Subscriptions',
                settings: 'Settings',
                projects: 'Projects',
                plans: 'Plans',
                responses: 'Responses'
            },
            password_reset: {
                title: 'Reset Password',
                description: 'Enter your email address and we will send you instructions to reset your password.',
                email: 'Email',
                submit: 'Send Reset Link'
            },
            reset_password: {
                title: 'Set New Password',
                description: 'Please enter your new password.',
                new_password: 'New Password',
                confirm_password: 'Confirm Password',
                submit: 'Reset Password'
            },
            email_verification: {
                title: 'Email Verification',
                description: 'Please verify your email address to continue.',
                resend: 'Resend Verification Email'
            }
        },
        es: {
            login: {
                login_now: 'Inicia sesión!',
                login_now_description: "Inicia sesión ahora para acceder a las herramientas de IA más poderosas!",
                forgot_password: '¿Olvidaste tu contraseña?',
                register_new_account: 'Registrar una nueva cuenta',
                username: 'Nombre de usuario',
                password: 'Contraseña',
                login: 'Iniciar sesión',
            },
            register: {
                register_now: '¡Regístrate ahora!',
                register_now_description: 'Da el primer paso para acceder a poderosas herramientas de IA.',
                username: 'Nombre de usuario',
                email: 'Correo electrónico',
                password: 'Contraseña',
                confirm_password: 'Confirmar Contraseña',
                register: 'Registrarse',
                company_step: {
                    title: 'Nombra tu Empresa',
                    description: 'Toda la información del cliente, plantillas de documentos y generaciones se mantendrán dentro de tu empresa.',
                    company_name: 'Nombre de la Empresa',
                    assistant_name: 'Nombre del Asistente',
                    assistant_context: 'Contexto del Asistente',
                    create_company: 'Crear Empresa'
                },
                final_step: {
                    start_now: 'Comenzar Ahora'
                },
                steps: {
                    user: 'Usuario',
                    company: 'Empresa',
                    register: 'Registro'
                }
            },
            sidenav: {
                select_company: 'Seleccionar Empresa',
                dashboard: 'Panel',
                ai_parameters: 'Parámetros de IA',
                ai_tokens: 'Tokens de IA',
                files: 'Archivos',
                sandbox: 'Sandbox',
                users: 'Usuarios',
                subscriptions: 'Suscripciones',
                settings: 'Configuración',
                projects: 'Proyectos',
                plans: 'Planes',
                responses: 'Respuestas'
            },
            password_reset: {
                title: 'Restablecer Contraseña',
                description: 'Ingresa tu correo electrónico y te enviaremos instrucciones para restablecer tu contraseña.',
                email: 'Correo Electrónico',
                submit: 'Enviar Enlace de Restablecimiento'
            },
            reset_password: {
                title: 'Establecer Nueva Contraseña',
                description: 'Por favor, ingresa tu nueva contraseña.',
                new_password: 'Nueva Contraseña',
                confirm_password: 'Confirmar Contraseña',
                submit: 'Restablecer Contraseña'
            },
            email_verification: {
                title: 'Verificación de Correo Electrónico',
                description: 'Por favor, verifica tu dirección de correo electrónico para continuar.',
                resend: 'Reenviar Correo de Verificación'
            }
        }
    }
})

export default i18n;
