import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { tokenExpired, getUserRole } from '@/helpers/authHelper'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['ADMIN', 'USER', 'SUPER_ADMIN']
    }
  },
  {
    path: '/companies',
    name: 'companies.index',
    component: () => import('@/views/Companies/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER_ADMIN']
    }
  },
  {
    path: '/companies/:id/edit',
    name: 'companies.edit',
    component: () => import('@/views/Companies/Edit.vue'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER_ADMIN']
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/Users/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['SUPER_ADMIN']
    }
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: () => import('@/views/Users/Edit.vue'),
    meta: {
      requiresAuth: true,
      roles: ['SUPER_ADMIN']
    }
  },
  {
    path: '/fields',
    name: 'fields.index',
    component: () => import('@/views/Fields/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients',
    name: 'clients.index',
    component: () => import('@/views/Clients/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients/:id/edit',
    name: 'clients.edit',
    component: () => import('@/views/Clients/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/files',
    name: 'files.index',
    component: () => import('@/views/Files/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'devices.index',
    component: () => import('@/views/Devices/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/groups',
    name: 'groups.index',
    component: () => import('@/views/Groups/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/groups/:id',
    name: 'groups.show',
    component: () => import('@/views/Groups/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sandbox',
    name: 'sandbox.index',
    component: () => import('@/views/Sandbox/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/subscriptions',
    name: 'subscriptions.index',
    component: () => import('@/views/Subscriptions/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/plans",
    name: "plans.index",
    component: () => import("@/views/Plans/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/plans/:id/edit",
    name: "plans.edit",
    component: () => import("@/views/Plans/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/responses',
    name: 'responses.index',
    component: () => import('@/views/Responses/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/PasswordReset.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password-confirm',
    component: () => import('@/views/ResetPasswordConfirm.vue')
  },
  {
    path: '/verify-email',
    name: 'email-verification',
    component: () => import('@/views/EmailVerification.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (tokenExpired()) {
      next({ name: 'login' });
      return;
    }

    if (to.meta.roles) {
      const userRole = getUserRole();
      if (!to.meta.roles.includes(userRole)) {
        next({ name: 'dashboard' });
        return;
      }
    }

    next();
  } else {
    next();
  }
});

export default router
