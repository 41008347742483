import { createApp } from 'vue'
import axios from 'axios';
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/i18n'
import '@/css/app.css';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/api'; // FastAPI backend

createApp(App)
    .use(router)
    .use(i18n)
    .mount('#app')
