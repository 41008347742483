<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="hero-content text-center">
      <div class="max-w-lg">
        <h1 class="text-5xl font-bold">Welcome to TAIEE</h1>
        <p class="py-6">Total AI Experience Engine</p>
        <div class="flex justify-center space-x-4">
          <RouterLink :to="{ name: 'login' }">
            <button class="btn btn-primary">Log In</button>
          </RouterLink>
          <RouterLink v-if="true" :to="{ name: 'register' }">
            <button class="btn btn-secondary">Join Now</button>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
